import React,{useRef,useState} from 'react';
import { Modal } from 'react-responsive-modal';

const UpdateAddressForm = ({isAddress,onClickCancel}) => {
    const [open, setOpen] = useState(true);
    const onCloseModal = () => setOpen(false);
    const onClickNo  = (e) => {
        onClickCancel(e);
    };
    const modalRef = useRef(null);
    return(
        <>
        { (isAddress === '/e911/updateaddress' && open) &&
        <div>
            <Modal classNames={{ modal: 'updateaddress-modal'}} ref={modalRef} initialFocusRef={modalRef} closeOnOverlayClick={false} open={open} center onClose={onCloseModal}>
                <div className="modal-wrapper updateaddress">
                    <h3>It appears that your registered location is not accurate for 911 emergencies.</h3>
                    <div className="msg">Would you like to update your registered location?</div>
                    <div className="primary">
                        <button type="button" title="Yes" onClick={onCloseModal} className="action submit primary-btn">
                            <span>Yes</span>
                        </button>
                        <button type="button" title="No" onClick={(e)=>{ onClickNo(e)}} className="action submit primary-btn">
                            <span>No</span>
                        </button>
                    </div>
                </div>              
            </Modal>
            </div>
        }
        </> 
    );
}

export default UpdateAddressForm;
export default function validate(values,isTCStatus) {
    let errors = {};
    
    if (!values.street_1) {
        errors.street_1 = 'This is a required field.';
    } 
    if (!values.city) {
        errors.city = 'This is a required field.';
    } 
    if (!values.region_id) {
        errors.region_id = 'This is a required field.';
    } 
    
    if (!values.postcode) {
        errors.postcode = 'This is a required field.';
    } else if (!/^[0-9]{5,5}$/.test(values.postcode)) {
        errors.postcode = 'Please enter valid 5 digits zip code.';
    }
    
    /*Added Below condition for tc-required=0*/
    if(!isTCStatus){
        
        if (!values.unavailablelimited) {
            errors.unavailablelimited = 'This is a required field.';
        } 
    
    }

    return errors;
  };
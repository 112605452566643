import React,{useRef} from 'react';
import { Modal } from 'react-responsive-modal';
import location from '../../location.png';
const SuggestedAddressPopup = ({showSuggestedPopup, responseAddress,closeSuggestedAddressPopup,populateAddress}) => {
    const modalRef = useRef(null);
    const {delivery_line_1, components: {city_name,zipcode,state_abbreviation}} = responseAddress?.data?.smartyAddress
    return(
        <>
        { showSuggestedPopup &&
        <div>
            <Modal classNames={{ modal: 'updateaddress-modal suggested-address-modal'}} ref={modalRef} 
            initialFocusRef={modalRef} closeOnOverlayClick={false}
             open={showSuggestedPopup} center onClose={closeSuggestedAddressPopup} focusTrapped={false}>
                <div className="modal-wrapper updateaddress">
                    <h3>Invalid Location</h3>
                    <div className="msg">
                        <img alt="Location" src={location} width="50px" height="50px"/>
                        The address you provided isn't accurate for e911 emergencies. Would you like to update your location to the following address instead?</div>
                    <strong>{`${delivery_line_1}, ${city_name} ${state_abbreviation}  ${zipcode}`}</strong>
                    <div className="primary">
                        <button type="button" title="Yes" onClick={populateAddress} className="action submit primary-btn">
                            <span>Yes</span>
                        </button>
                        <button type="button" title="No" onClick={closeSuggestedAddressPopup} className="action submit secondary-btn">
                            <span>No</span>
                        </button>
                    </div>
                </div>              
            </Modal>
        </div>
        }
        </> 
    );
}

export default SuggestedAddressPopup;
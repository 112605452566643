import { useState, useEffect } from 'react';

const useForm = (callback, validate,isTCStatus) => {

  const [values, setValues] = useState({});
  const [focusElement, setFocusElement] = useState();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, isTCStatus));
    setIsSubmitting(true);
  };

  
  const handleChange = (event) => {
    event.persist();
    if(event.target.type === 'checkbox'){
      setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
    }else{
      setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    }
  };
  
  const handleFocus = (event) => {
    event.persist();
    setFocusElement(event.target.name);
  };

  const handleBlur = (event) => {
    event.persist();
    setFocusElement();
  };
  
  const resetForm = (event) =>{
    setValues({})
  }
  return {
    handleChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    resetForm,
    isSubmitting,
    values,
    focusElement,
    errors,
  }
};

export default useForm;
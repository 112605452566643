import React from 'react';

const TermsAndCondition = () => {
  return(
    <>
    <div className="modal-wrapper">
    <h3>DISH Wireless L.L.C. Customer Acknowledgement of Limitations on 911 Emergency Services and Other Legal Disclosures</h3>
    <p><strong>PLEASE READ THIS NOTICE CAREFULLY. YOUR AFFIRMATIVE ACKNOWLEDGMENT IS REQUIRED AS A CONDITION OF USE OF THE DWLLC SERVICE.</strong></p>
<p>DISH Wireless L.L.C. (DWLLC) discloses certain limitations of our service that you consent to as a condition of using our Service. By using our Service Plan, you acknowledge that you understand and agree to all of these terms and conditions of use.</p>
<p><strong>BY CLICKING “I AGREE,” PRIOR TO SIGNING UP FOR PROJECT GENESIS SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTAND, AND ACCEPT THE LIMITATIONS ASSOCIATED WITH THE 911 AND E-911 SERVICES AVAILABLE THROUGH DWLLC, LIMITATIONS ON OTHER REGULATORY SERVICES AVAILABLE THROUGH DWLLC, AND DWLLC’S POLICIES, INCLUDING BUT NOT LIMITED TO DISCLOSURE OF INFORMATION IN RESPONSE TO LAWFUL ORDER OR REQUEST.</strong></p>
<p><br/><strong>IF YOU DO NOT AGREE, YOU ARE NOT AUTHORIZED TO USE DWLLC SERVICES.</strong></p>
<p>&nbsp;</p>
<p><strong>Limitations of Calls to 911 and 911 Service</strong></p>
<p>&nbsp;</p>
<p>This is an explanation of limitations on DWLLC service of emergency calls to 911 (“911 Service”). Below, we address limitations on (1) 911 service generally, (2) calls to 911 made via DWLLC mobile voice service, (3) calls made to 911 via DWLLC WiFi Calling Service, and (4) Text-to-911 Service. When we say “911,” we mean that term to also include “Enhanced 911” or “E911,” which is 911 plus some location and call-back functionalities designed to locate mobile wireless callers in an emergency. E911 only works where 911 call reception and dispatch centers called Public Safety Answering Points (“PSAPs”) have installed E-911 capable equipment.</p>
<p><br/><strong>General 911 Service Limitations.</strong> There are service limitations that are specific to types of services over which 911 calls are made (for example, calls to 911 made via DWLLC mobile voice service, Texts to 911, and calls made to 911 via DWLLC WiFi Calling Service), as explained below, but circumstances that may cause 911c calls over any service platform to be limited or unavailable including, but not limited to:</p>
<p>&nbsp;</p>
<ul>
<li>wireless network outage,</li>
<li>network congestion,</li>
<li>power outages or other loss of electrical or battery power,</li>
<li>the PSAP’s ability or willingness to receive and respond to an emergency call or text message,</li>
<li>the PSAP not having shared or updated its service boundary,</li>
<li>if you are prepaid customer with a zero-dollar ($0) or negative balance,</li>
<li>you are a VoWiFi 911 caller that has physically moved out of range of a WiFi hotspot/access point<br/>you have a weak or non-existent GPS signals or other radiofrequency signals,</li>
<li>signal interference or degradation,</li>
<li>the device is not located in the United States of America, and/or</li>
<li>other technical limitations.</li>
</ul>
<p>Please remember that all of DWLLC Service Plans rely on radio waves, which are part of the natural environment. That means that DWLLC Service Plans are affected by environmental factors that can block or degrade wireless communications signals including geography, terrain, obstructions (e.g., buildings or trees), storms and inclement weather, natural disasters, and even space weather (i.e., solar flares). In addition, DWLLC uses device-based location methods of locating 911 callers that, though generally reliable, may not work in every circumstance. Thus, we cannot guarantee that we will provide PSAPs with your precise location.</p>
<p><br/><strong>Emergency Mobile Wireless Calls to 911</strong>: You agree that DWLLC may, whenever you dial 911, and without further notice to you, use or disclose personal information, including but not limited to data such as a civic address that you have provided and/or device-based location information (including, but not limited to, horizontal and vertical location coordinates). The Federal Communications Commission (“FCC”) requires DWLLC to collect and share the location of 911 callers for the limited purpose of enabling first responders to respond to 911 emergencies.</p>
<p>You further acknowledge that even though DWLLC will make commercially reasonable efforts to route your 911 call to the appropriate 911 emergency dispatch center or PSAP, calls may be routed to a PSAP covering a neighboring or distant area due to incorrect location information supplied by you, inaccurate or unavailable routing information, mechanical failure, and error.</p>
<p><strong>Text to 911</strong>: IN AN EMERGENCY, WHENEVER POSSIBLE, CALL 911. Text-to-911 is available only in limited areas because not all local public safety officials are equipped to handle them. You cannot use Text-to-911 unless you are in an area where the PSAP accepts Text-to-911 messages and the device you are using has both an active messaging plan and a cellular-data connection (having only a Wi-Fi connection will not be sufficient).</p>
<p>The Text-to-911 service is not available when roaming. In addition, DISH offers Text-to-911 only when you are communicating on DWLLC’s facilities – if you roam to another network, Text-to-911 will be unavailable to you.</p>
<p>Where available, text-to-911 service may allow standard SMS text message communication with a 911 call center. Delivery or receipt of messages is not guaranteed and messages may fail, be delayed or be out of sequence. Text-to-911 may only be available only in certain limited areas where it has been requested and implemented by local public safety officials.</p>
<p>If you are prepaid customer with a zero-dollar ($0) or negative balance, a PSAP will not be able to text you back, which could result in the PSAP calling your number from a phone number that does not appear to be from 911 (i.e., an administrative phone number of the PSAP).</p>
<p>If you use Text-to-911, be prepared to provide your precise physical location to emergency responders (as with all 911 communications).</p>
<p><strong>DWLLC Wi-Fi Calling Service</strong>&nbsp;completes telephone calls using Voice over Internet Protocol (“VoIP”) technology by connecting a capable DWLLC’ wireless device to a router using a Wi-Fi signal and is intended primarily for use within the domestic United States (“U.S”). VoIP telephony is fundamentally different from traditional telephone service and has inherent limitations. In general, during a 911 call, if DWLLC network is available, DWLLC will utilize its network – even if a broadband Internet connection is otherwise available. If DWLLC network is unavailable for any reason, DWLLC will utilize using a WiFi signal, subject to the limitations described below.</p>
<p>The WiFi Calling Service, including 911 calling, may be unavailable or limited in some circumstances, including without limitation the circumstances described below.&nbsp;While the WiFi Calling Service may be available in a limited number of countries outside the U.S., calls to 911 and other international emergency service abbreviated dialing codes, such as 1-1-2 or 9-9-9, WILL NOT function over Wi-Fi and should not be used or relied upon during an emergency.&nbsp;Customer certifies awareness of the WiFi Calling Service limitations outlined below and that WiFi Calling Service will not be used by anyone other than Customer without first notifying the end user of such limitations.</p>
<p>DWLLC is responsible only for any WiFi Calling Service that it provides. If you download or software, applications, or services (including voice applications) provided by anyone other than DWLLC, your calls, including calls to 911, may not work the same as services offered by DWLLC, and might not work at all. You are solely responsible for any third–party applications, data, content, or services that you access, download or use on your device.</p>
<p>When you call 911, always state where you are to the person who answers (i.e., identify the physical location where you can be found). 911 call takers still need you to tell them where you are.</p>
<p>DWLLC will collect from you an address where you want first responders to search for you in the case of an emergency 911 call. We call this the “Registered Location.” You will not be allowed to use the WiFi Calling Service until you provide a Registered Location. It is vitally important that you keep the Registered Location current and update it whenever you change your location. You can do this at any time in the settings of your device. It may take some time to process your request and there could be delay from the time you submit an updated Registered Location and the time it can be used for routing to the appropriate PSAP or delivered to the PSAP. You are responsible for keeping your Registered Location current and accurate at all times.</p>
<p>&nbsp;</p>
<p><strong>911 Limitations of WiFi Calling Service.</strong></p>
<ul>
<li><strong>Relocation of End User Devices.</strong> If Customer uses the WiFi Calling Service in a location other than the Registered Location for that device, 911 calls may not be routed to the appropriate Public Safety Answering Point ("PSAP") for the end user’s current physical location.</li>
<li><strong>Use of “Non-Native” Telephone Numbers.</strong>&nbsp;If Customer uses the WiFi Calling Service with an assigned telephone number that is outside the rate center of the Registered Location, the PSAP for the Registered Location may not recognize the telephone number for call-back or other informational purposes.</li>
<li><strong>Broadband Connection Failures.</strong>&nbsp;The WiFi Calling Service will not be able to make calls if Customer loses connectivity to the Internet. Due to Internet congestion and network design issues, 911 calls placed through the WiFi Calling Service may sometimes produce a busy signal, experience unexpected answering wait times, or take longer to answer than 911 calls placed through traditional telephone networks.</li>
<li><strong>Loss of Electrical Power.</strong>&nbsp;The WiFi Calling Service will not operate if Customer has lost electric power for the WiFi Calling Service or for other WiFi Calling Service-enabling equipment. After a power outage, Customer may need to reset or reconfigure enabling equipment or devices before being able to use the WiFi Calling Service.</li>
<li><strong>Updating Registered Locations in ALI Databases.</strong>&nbsp;If Customer does not correctly identify the physical location of the WiFi Calling Service when defining the Registered Location, 911 calls through the WiFi Calling Service may not reach the correct PSAP. At initial activation of the WiFi Calling Service, and following any update to Registered Locations, there may be some delay before complete and accurate information is passed to the local emergency service call taker.</li>
<li><strong>Viruses and security updates.</strong> You must maintain Internet security protections, including virus protections when you access or use any VoIP technology. Failure to do this could prevent you from using the WiFi Calling Service and you may not be able to reach 911 emergency services.</li>
<li><strong>Registered Locations.</strong>&nbsp;DWLLC relies on the Registered Location of the WiFi Calling Service at the time a 911 call is placed to route the call to the appropriate PSAP within the domestic U.S. and to provide the PSAP with your location. Your device may automatically attempt to determine the Registered Location information based on its location. You should verify and update the Registered Location information regularly and when accessing a Wi-Fi network with the same name (SSID) that may exist in multiple locations. Registered Location information is not automatically updated for a location with the same network name or for other non-cellular devices being used with the WiFi Calling Service. If Customer does not update the Registered Location or it is not complete, DWLLC may attempt to route a 911 call based on earlier Registered Location information, which may not match Customer’s actual location and may cause a 911 call to be misrouted and/or provide a PSAP with incorrect location information. Always be prepared to provide your actual location to a call taker. If you decide to link devices such as laptops, tablets, or smart watches to a Voice over IP application or service that allows all of the linked devices to use a DWLLC -provisioned telephone number or that cause all calls to those devices to ring simultaneously with a phone (e.g., Companion Service), you agree to provide DWLLC a Registered Location for each device and to update those Registered Locations whenever there is a change to the location of each device.</li>
<li><strong>PSAP Limitations.</strong>&nbsp;The PSAP designated to receive 911 calls for a particular Registered Location through the WiFi Calling Service may not have a system configured for all 911 services. The PSAP may not be able to capture, retain or otherwise determine the phone number, Registered Location, or physical location of the VoIP device placing the 911 call. Accordingly, Customer must be prepared to provide this information to the PSAP. Until and unless Customer does so, the emergency service call taker may be unable to call Customer back or to otherwise assist Customer in the event of an emergency.</li>
<li><strong>Warning Labels and Certifications.</strong>&nbsp;Customer must notify end users of the WiFi Calling Service about the 911 limitations of the WiFi Calling Service as outlined in this acknowledgement. DWLLC provides Customer with warning labels regarding the limitations or unavailability of 911 services. Customer will place labels on or near each VoIP device used to access the WiFi Calling Service. Customer will acknowledge and complete all advisory notices and certifications received from DWLLC regarding 911 service. Click&nbsp;here&nbsp;to download a warning label.</li>
<li><strong>Customer Choice.</strong> Each Customer must carefully evaluate the individual circumstances in deciding whether to rely solely upon the WiFi Calling Service for 911 calling or to make necessary provisions for access to emergency calling services (e.g. maintaining a conventional landline phone as a backup means of completing emergency calls).</li>
<li><strong>Other Limitations.</strong>&nbsp;In addition to 911 limitations, the WiFi Calling Service does not support Wireless Emergency Alerts and may not support other wireless products and services. Other non-cellular devices capable of Wi-Fi Calling may not support a 911 call over a wireless carrier network and may be limited to Wi-Fi Calling capabilities only, if available and connected. The primary device user can deactivate WiFi Calling Service on all other associated devices at any time and thereby eliminate Wi-Fi Calling, including 911 calling, from those devices.</li>
<li><strong>Limitation of Liability and Release.</strong>&nbsp;DWLLC is not liable for any failure to access emergency services when using the WiFi Calling Service. Customer hereby releases and discharges DWLLC, its affiliates and subsidiaries, and their respective officers, directors, employees, agents and vendors affiliated with the WiFi Calling Service from any and all claims, losses, damages, fines, penalties, costs, expenses and liability arising out of Customer’s or any third party’s use of the WiFi Calling Service, and hereby acknowledges that DWLLC is not liable for any failure or outage of the WiFi Calling Service, including those related to 911 dialing in the USA and Emergency Services Calling in other countries where the WiFi Calling Service is permitted by DWLLC. This release and waiver extends to all claims of any kind or nature whether foreseen, known or unknown.</li>
</ul>
<p><br/><strong>Roaming During a 911 Call.</strong></p>
<p>&nbsp;</p>
<p>As is the case in the wireless industry generally, if you roam from DWLLC’s network to the network of another mobile wireless carrier during an ongoing 911 call, your 911 call could drop, in which case you would need to initiate a new 911 call on the network to which you roamed. &nbsp;</p>
<p>&nbsp;</p>
<p><strong>911 communications via Real-Time Text (RTT), Telecommunications Relay Service (TRS), and Teletypewriter (TTY)</strong></p>
<p>&nbsp;</p>
<p>DISH does not support TRS for 911 purposes. Instead, DISH supports Real-Time Texts to 911 (RTT-to-911) in lieu of using Telecommunications Relay Service for 911, as the FCC allows. DISH also supports 711, but you should never dial 7-1-1 for emergency services (instead, use RTT-to-911 or call 911 if you are able).</p>
<p>But not all PSAPs can support RTT-to-911, so RTTs-to-911 may revert to Teletypewriter (TTY) format that PSAPs can handle. If you use RTT to dial 911 (including RTT that has reverted to TTY) and your location cannot be determined, the 911 communication may be routed to an Emergency Call Relay Center (ECRC). The ECRC cannot support RTT. Instead, using TTY equipment, the ECRC will gather the needed details from you, including your location information. Accordingly, you should be prepared to describe your location. Once the ECRC call taker has confirmed your location, he or she will inform the relevant PSAP. The PSAP may contact you directly using TTY equipment, but it would still appear to be RTT on your device.</p>
<p><strong>Companion Service.</strong> If you use an Apple iPhone on DWLLC network, you may be able to use your Apple ID to associate devices such as laptops, tablets, and smart watches (“Adjunct Devices”) with the primary telephone number assigned to the iPhone. By using the Companion Service, you agree to provide DWLLC with a Registered Location for each Adjunct device to help avoid circumstances where an Adjunct Devices are in different physical locations than the location of the primary telephone. You must keep the Registered Location for each device used in the Companion Service current because, based on how the Apple Companion service works, devices that you link by the primary DWLLC telephone number do not need to be in the same physical location. In fact, Adjunct Devices could be separated from the location of record of the primary telephone by many miles, which could have the effect of leading 911 emergency responders to a location where you are not physically located. By using the Companion service, you also acknowledge and agree that all of your linked devices could ring when someone calls your primary telephone, including 911 PSAP call takers who may need to call you back after you place a call or text to 911.</p>
<p><br/><strong>2. Wireless Emergency Alerts (“WEAs”)</strong>: WEAs are free messages that DWLLC transmits to your device on behalf of authorized, national, state or local emergency and public-safety authorities. DWLLC does not create WEAs – WEAs are originated by public safety officials who send them through the Federal Emergency Management Agency to DWLLC. DWLLC role is to broadcast WEAs over our cellular network to your device.</p>
<p>&nbsp;</p>
<p><strong>Notice regarding transmission of Wireless Emergency Alerts (Commercial Mobile Alert System):</strong></p>
<ul>
<li>DWLLC has chosen to offer wireless emergency alerts, including enhanced geo-targeting, within portions of its service area, as defined by the terms and conditions of its service agreement, on wireless emergency alert capable devices. There is no additional charge for these wireless emergency alerts.</li>
<li>Wireless emergency alerts, including enhanced geo-targeting, may not be available on all devices or in the entire service area, or if a subscriber is outside of the DWLLC service area. For details on the availability of this service and wireless emergency alert capable devices, including the availability and benefits of enhanced geo-targeting, call us at (833) 238-1780, or go to https://www.fema.gov/emergency-managers/practitioners/integrated-public-alert-warning-system/public/wireless-emergency-alerts/geographic-accuracy-wea.</li>
<li><strong>Notice required by FCC Rule 47 CFR 10.240 (Commercial Mobile Alert System).</strong></li>
</ul>
<p><br/>WEAs broadcast at a set volume that cannot be changed. WEAs may override any blocks you have on your phone, such as do not disturb, but they should not unduly interfere with making phone calls, sending emails, or completing online transactions.</p>
<p>If you receive a WEA, take any action recommended in the alert and check your local media or the National Weather Service for additional information.</p>
<p>There are different types of WEA messages:</p>
<ul>
<li>Presidential Alerts – This kind of alert can only be sent by the President of the United States.</li>
<li>Imminent Threat – This kind of alert is categorized as either Extreme or Severe. Examples of this type of alert might be a weather alert or a chemical spill.</li>
<li>AMBER Alerts –&nbsp;America’s Missing: Broadcast Emergency Response&nbsp;or “AMBER” Alerts can be sent as a WEA message.</li>
<li>Public Safety Alert – Allows public safety agencies to communicate directly with the public.</li>
</ul>
<p><br/>You may opt out of imminent threat alerts or AMBER alerts, but you may not opt out of Presidential alerts. DWLLC recommends that you keep all alerts activated, but if you choose to opt out of alerts, but see your phone’s User Manual for more information about Settings.</p>
<p>In transmitting emergency alerts as required by federal law, DWLLC, including its officers, directors, employees, vendors, and agents, will not be liable to any subscriber to, or user of, DWLLC’ wireless service or equipment for any act or omission related to or any harm resulting from the transmission of, or the failure to transmit, an emergency alert; or the release to a government entity or agency, public safety, fire service, law enforcement official, emergency medical service, or emergency facility of subscriber information used in connection with delivering an emergency alert.</p>
<p>To learn more about WEAs, the limited availability of this service and enhanced geo-targeting, and wireless emergency alert capable devices, call us at (833) 238-1780, or go to <a tabIndex="0" href="http://www.fema.gov/emergency-managers/practitioners/integrated-public-alert-warning-system/public/wireless-emergency-alerts/geographic-accuracy-wea" target="_blank" rel="noopener">https://www.fema.gov/emergency-managers/practitioners/integrated-public-alert-warning-system/public/wireless-emergency-alerts/geographic-accuracy-wea</a>.</p>
<p>&nbsp;</p>
<p><strong>3. Legal Process and Other Disclosures.</strong> By using any DWLLC service, you agree that DWLLC may access, monitor, use or disclose your personal information or communications to do things such as: comply with the law or respond to legal process or lawful authorizations or requests, including but not limited to requests made under exigent circumstances; protect the rights or property of us, our agents, members, our customers, and others including to enforce our agreements, policies and terms of use, and respond to emergencies. Personal information we collect includes information you give us, such as (without limitation) name, postal address, telephone number, e-mail address, date of birth, social security number or other government identification number, demographics, activities, payment information, location information, and personal preferences.</p>
<p>Like all mobile wireless companies, we are required by law to provide information to government and law enforcement entities, as well as parties to civil lawsuits, by complying with court orders, subpoenas, lawful discovery requests and other legal requirements. For information on how to view our transparency reports, please call us at (833) 238-1780.</p>
<p>For more information about uses of your information, please carefully review <a href="http://www.genesis5g.com/support/legal/privacy-policy" target="_blank" rel="noopener">https://www.genesis5g.com/support/legal/privacy-policy</a>.</p>
<p><strong>4. Do Not Call Policy.</strong> You should carefully review information regarding our Do Not Call Policy if you decide to stop receiving telemarketing calls from us. For more information, visit <a tabIndex="0" href="http://www.genesis5g.com/support/legal/terms-conditions" target="_blank" rel="noopener">https://www.genesis5g.com/support/legal/terms-conditions</a>.</p>
<p><strong>5. Automated Call Blocking.</strong> Blocked calls are stopped in the network and never reach your device. Blocked calls are not directed to voicemail. In addition to providing you with method for blocking unwanted calls and robocalls, DWLLC automatically blocks calls in the network from telephone numbers which are invalid or are on a DNO (Do Not Originate) list. These numbers should not be making calls in the first place. In addition, the FCC requires DWLLC block calls of a carrier that is not registered in the Robocall Mitigation Database (RMD) as part of the FCC’s efforts to reduce robocalls.</p>
<p>&nbsp;</p>
<p>DWLLC also uses reasonable network-based call analytics that incorporated caller ID authentication information to identify and block categories of calls that, consistent with FCC requirements, are highly likely to be illegal. As part of this program:</p>
<p>&nbsp;</p>
<ul>
<li>911 emergency calls are not blocked</li>
<li>blocking services are provided with no additional line-item charge</li>
<li>the network-based analytics are applied in a non-discriminatory, competitively neutral manner</li>
<li>all reasonable efforts are taken to ensure that calls from public safety answering points and government emergency numbers are not blocked, and</li>
<li>we provide a system for redress, including a single point of contact, if you believe calls were inappropriately blocked</li>
</ul>
<p>For more information about our “Do Not Call Policy”, please ask a sales representative about our “Do Not Call Policy”, or visit: <a href="http://www.genesis5g.com/support/legal/terms-conditions" target="_blank" rel="noopener">https://www.genesis5g.com/support/legal/terms-conditions</a>. If you believe that DWLLC has incorrectly blocked a call, you can make a call blocking error complaint by contacting:<br/>&nbsp;</p>
<p>&nbsp; &nbsp; &nbsp;George Smith<br/>&nbsp; &nbsp; &nbsp;Regulated Services<br/>&nbsp; &nbsp; &nbsp;Wireless Network Engineering<br/>&nbsp; &nbsp; &nbsp;DISH Wireless L.L.C.<br/>&nbsp; &nbsp; &nbsp;5701 S. Santa Fe Drive<br/>&nbsp; &nbsp; &nbsp;Littleton, Colorado 80120-1813<br/>&nbsp; &nbsp; &nbsp;George.Smith@DISH.com<br/>&nbsp; &nbsp; &nbsp;303-706-5876</p>
<p>&nbsp;</p>
<p><strong>6. Hearing Aid Compatibility</strong></p>
<p>DWLLC offers many phone models that meet the Hearing Aid Compatibility standards set by the FCC. Visit the&nbsp;FCC web page&nbsp;for more information about wireless HAC rules and service provider regulations. For more information about DWLLC Hearing Aid Compatible Devices, please call us at (833) 238-1780 or contact your Device manufacturer.</p>
<p>&nbsp;</p>
<p><strong>7. Service Availability.</strong> <br/>Notwithstanding any term or condition of these Terms of Service to the contrary, DWLLC cannot guarantee that the DWLLC Services will always function without disruptions, delay or other imperfections. The specific network coverage Users will receive will depend on Your Service Plan, Your Equipment, including, without limitation, the WiFi routers a Mobile Device can access, and/or radio transmissions a Mobile Device can pick up. By using the DWLLC Services, You agree to keep Your Equipment up to date, including by downloading the latest upgrades and updates of the DWLLC App and other software. Failure to do so can result in temporary or permanent disconnection of the DWLLC Services, including termination. Coverage is not available everywhere and service speeds are not guaranteed. Actual speeds will vary. Gaps in coverage exist within DWLLC' estimated coverage areas for radio transmissions, when applicable, that, along with other factors both within and beyond the control of DWLLC (network problems, network or internet congestion, software, signal strength, the Mobile Device, structures, buildings, weather, geography, topography, server speeds of the websites accessed, etc.), may result in dropped and blocked connections, slower service speeds, or otherwise impact the quality of service. Services that rely on location information, such as E911 and GPS navigation, depend on a Mobile Device's ability to acquire satellite signals (which typically are not available indoors) and network coverage. While a Mobile Device is receiving a software update, a User may be unable to use a Mobile Device in any manner until the software update is complete.</p>
<p>&nbsp;</p>
<p><strong>8. Porting Numbers.</strong></p>
<p>If you wish to port any existing telephone numbers from another carrier to DWLLC, you must complete the authorized porting process using the Electronic Tools made available to you by DWLLC; You agree to follow DWLLC's then-current Policies, as directed by the DWLLC Help Team. In addition to completing the authorized porting process using the Electronic Tools, DWLLC may also require a recent copy of your current phone bill which contains your billing telephone number and a record of any other telephone number(s) to be ported to DWLLC. Valid authorization, bill copy(ies) and/or other records may be required by DWLLC before DWLLC will initiate a port request and obtain a Confirmed Port Date. The Confirmed Port Date will be established pursuant to industry and any applicable regulatory standards; however, since the time required to port number(s) will be affected by the accuracy of information provided to DWLLC by You, as well as the actions of your pre-existing voice provider, DWLLC makes no assurances regarding the time required to port any number(s).</p>
<p>&nbsp;</p>
<p><strong>9. Acceptance</strong></p>
<p><strong>BY CLICKING “I AGREE,” YOU ACKNOWLEDGE AND AGREE THAT:</strong></p>
<p>NEITHER DWLLC, NOR ITS AFFILIATES,NOR ITS UNDERLYING CARRIER, NOR ANY OTHER THIRD PARTIES INVOLVED IN THE ROUTING, HANDLING, DELIVERY, OR ANSWERING OF EMERGENCY SERVICES OR IN RESPONDING TO EMERGENCY CALLS, NOR THEIR OFFICERS OR EMPLOYEES, MAY BE HELD LIABLE FOR ANY ACTUAL OR ALLEGED CLAIM, DAMAGE, LOSS, FINE, PENALTY OR COST (INCLUDING, WITHOUT LIMITATION, ATTORNEYS FEES) AND YOU HEREBY WAIVE ANY AND ALL SUCH CLAIMS OR CAUSES OF ACTION, ARISING FROM OR RELATING TO THE PROVISION OF ALL TYPES OF EMERGENCY SERVICES TO YOU;</p>
<p>YOU ARE INDEMNIFYING AND HOLDING HARMLESS DWLLC FROM ANY ACTUAL OR ALLEGED CLAIM OR ACTION FOR ANY CALLER PLACING SUCH A CALL. YOU ACKNOWLEDGE AND AGREE THAT ANY INJURY ARISING OUT OF MISROUTED 911 CALLS, REGARDLESS OF WHETHER THE CALL WAS ROUTED BY A PUBLIC SAFETY ANSWERING POINT OR AN OFFICIAL EMERGENCY OPERATOR, IS NOT THE FAULT NOR LIABILITY OF DWLLC AND YOU HOLD DWLLC HARMLESS FROM ANY DAMAGES OR LIABILITIES; THE LIMITATIONS APPLY TO ALL CLAIMS REGARDLESS OF WHETHER THEY ARE BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, PRODUCT LIABILITY, TORT AND ANY OTHER THEORIES OF LIABILITY;</p>
<p>YOU HAVE READ AND UNDERSTAND THE LIMITATIONS ASSOCIATED WITH THE 911 SERVICES AVAILABLE THROUGH DWLLC, INCLUDING ANY MOBILE VoWiFI 911 SERVICES THAT MAY BE AVAILABLE, SUBJECT TO THE LIMITATIONS DESCRIBED ABOVE;</p>
<p>YOU ARE ABLE TO ELECTRONICALLY ACCESS AND PRINT THIS AGREEMENT AND THAT CLICKING “I AGREE” CONSTITUTES YOUR VALID ELECTRONIC SIGNATURE.</p></div>
    </> 
  );
}

export default TermsAndCondition;
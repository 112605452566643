
import {Routes , Route } from "react-router-dom" ;
import Form from './components/e911/Form';
import Interrupted from './components/e911/Interrupted';

import './App.css';

function App(){ 
   return ( 
      <div className="App"> 
        <Routes>
            <Route path="/e911" element={<Form/> } /> 
            <Route path="/e911/updateaddress" element={<Form/> } /> 
            <Route path="/interrupted" element={<Interrupted/> } /> 
       </Routes> 
    </div> 
)} 
export default App ;
import React from 'react';

function Interrupted() {
  return (
      <div className="interuppted-msg">
          <h1>Interrupted</h1>
          <p>Oops! Your account ran out of data or you missed a payment, please log into my account for more details.</p>
      </div>
      
  );
}

export default Interrupted;

import React from 'react';

function Loader() {
  return (
      <div className={'backdrop'}>
            <div className="z-20 loading-container relative">
                <div className="loading-container animation-2 top-position-30">
                    <div className="shape shape1"></div>
                    <div className="shape shape2"></div>
                    <div className="shape shape3"></div>
                    <div className="shape shape4"></div>
                </div>
            </div>
      </div>
  );
}

export default Loader;
